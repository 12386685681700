@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */

// custom variables theme for tailwind
@import './theme/variable.scss';

// override material design theme
@import '../../../libs/theme/src/bbf-app.scss';

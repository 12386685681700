.MuiButtonBase-root.MuiButton-root {
  @apply shadow-none px-5 py-2.5 rounded-lg text-body transition-all  box-border font-medium normal-case h-[41px];

  &.MuiButton-colorError {
    &.MuiButton-contained {
      @apply bg-accent-red text-white;
      &:hover:not(.Mui-disabled) {
        @apply bg-red-400;
      }

      &:focus {
        @apply bg-accent-red  outline-[3px] border-0 outline outline-red-200;
      }
    }

    &.MuiButton-outlined {
      @apply bg-white text-accent-red border border-solid border-accent-red;
      &:hover:not(.Mui-disabled) {
        @apply bg-red-50 text-red-600;
      }

      &:focus {
        @apply bg-red-50 text-accent-red border-0 outline outline-[3px] outline-red-200;
      }

      &.Mui-disabled {
        @apply border-neutral-400 text-neutral-400;
      }
    }

    &.MuiButton-text {
      @apply text-accent-red;
      &:before {
        content: '';
        @apply bg-accent-red;
        clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
      }
      &:hover:not(.Mui-disabled) {
        @apply text-red-600;
      }

      &:focus {
        @apply text-accent-red;
      }
    }
  }

  &.MuiButton-colorSuccess {
    &.MuiButton-contained {
      @apply bg-secondary-light text-white;
      &:hover:not(.Mui-disabled) {
        @apply bg-green-800;
      }

      &:focus {
        @apply bg-accent-red  outline-[3px] border-0 outline outline-red-200;
      }
    }

    &.MuiButton-outlined {
      @apply bg-white text-secondary-light border border-solid border-secondary-light;
      &:hover:not(.Mui-disabled) {
        @apply bg-green-800 text-green-600;
      }

      &:focus {
        @apply bg-green-800 text-secondary-light border-0 outline outline-[3px] outline-red-200;
      }

      &.Mui-disabled {
        @apply border-neutral-400 text-neutral-400;
      }
    }

    &.MuiButton-text {
      @apply text-secondary-light;
      &:before {
        content: '';
        @apply bg-secondary-light;
        clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
      }
      &:hover:not(.Mui-disabled) {
        @apply text-green-600;
      }

      &:focus {
        @apply text-secondary-light;
      }
    }
  }
  &.MuiButton-colorSecondary {
    &.MuiButton-contained {
      @apply bg-secondary-light text-white;
      &:hover:not(.Mui-disabled) {
        @apply bg-secondary-300;
      }

      &:focus {
        @apply bg-accent-red outline-[3px] border-0 outline outline-red-200;
      }
    }

    &.MuiButton-outlined {
      @apply bg-white text-secondary-light border border-solid border-secondary-light;
      &:hover:not(.Mui-disabled) {
        @apply bg-transparent text-secondary-300;
      }

      &:focus {
        @apply bg-transparent text-secondary-dark border-0 outline outline-[3px] outline-red-200;
      }

      &.Mui-disabled {
        @apply border-neutral-400 text-neutral-400;
      }
    }

    &.MuiButton-text {
      @apply text-secondary-light;
      &:before {
        @apply hidden;
      }
      &:hover:not(.Mui-disabled) {
        @apply text-secondary-300 bg-transparent;
      }

      &:focus {
        @apply text-secondary-dark bg-transparent;
      }
    }
  }

  &.MuiButton-contained {
    @apply bg-primary-800 text-white;
    &:hover:not(.Mui-disabled) {
      @apply bg-primary-600;
    }

    &:focus {
      @apply bg-primary-main  outline-[3px] outline-primary-400;
    }

    &.Mui-disabled {
      @apply bg-neutral-400 text-neutral-300 cursor-not-allowed pointer-events-auto;
    }
  }

  &.MuiButton-outlined {
    @apply bg-white text-primary-main border border-solid border-primary-900;
    &:hover:not(.Mui-disabled) {
      @apply bg-primary-50;
    }

    &:focus {
      @apply bg-primary-50  border-0 outline outline-[3px] outline-primary-400;
    }

    &.Mui-disabled {
      @apply border-neutral-400 text-neutral-400;
    }
  }

  &.MuiButton-ghost {
    @apply border-0;
    &:focus {
      @apply outline-0 border border-solid border-primary-main;
    }

    &.Mui-disabled {
      @apply bg-neutral-50;
    }
  }

  &.MuiButton-text {
    @apply bg-white text-primary-main h-[22px] w-fit p-0 max-w-none;
    &:before {
      content: '';
      @apply absolute bottom-0 h-[1px] w-0 bg-primary-800 ease-out duration-300 left-0;
      clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
    }
    &:hover:not(.Mui-disabled) {
      @apply text-primary-800  relative;
      // &:before {
      //   @apply w-full;
      // }
    }

    &:focus {
      @apply text-primary-main;
      // &:before {
      //   @apply w-full;
      // }
    }

    &.Mui-disabled {
      @apply text-neutral-400;
    }
  }

  &.Mui-disabled {
    @apply cursor-not-allowed pointer-events-auto;
  }

  &:hover {
    @apply shadow-none;
  }
}

.MuiSwitch-root {
  .MuiSwitch-switchBase {
    @apply bg-white w-[26px] h-[26px] p-0 m-[3px] top-2;
  }
  .MuiSwitch-switchBase:not(.Mui-checked) {
    @apply bg-neutral-400 left-2;
  }
  .MuiSwitch-switchBase.Mui-checked {
    @apply bg-white text-primary-main translate-x-9 -left-2;
    &:hover {
      @apply outline-8 bg-white outline-[#8076CD1A] outline;
    }
  }
  .MuiSwitch-track {
    @apply bg-neutral-200 opacity-100 rounded-[100px];
  }

  &.MuiButtonBase-root.Mui-disabled {
    .MuiSwitch-track {
      @apply bg-neutral-200 cursor-not-allowed;
    }

    @apply bg-neutral-400 cursor-not-allowed;
  }
}

.MuiSwitch-root.MuiSwitch-sizeSmall {
  .MuiSwitch-switchBase {
    @apply size-4 p-0 m-0 top-1;
  }
  .MuiSwitch-switchBase:not(.Mui-checked) {
    @apply left-1;
  }
  .MuiSwitch-switchBase.Mui-checked {
    @apply translate-x-9 -left-3;
  }
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled)
  + .MuiSwitch-track {
  @apply bg-primary-main opacity-100;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked.Mui-disabled {
  @apply bg-neutral-400 text-neutral-200;
  & + .MuiSwitch-track {
    @apply bg-neutral-200 opacity-100;
  }
}

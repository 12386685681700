.MuiFormControl-root.MuiTextField-root {
  @apply w-full h-15;
  .MuiInputBase-root {
    @apply rounded-lg outline-0 box-border;

    // field set
    .MuiOutlinedInput-notchedOutline {
      @apply border-neutral-500;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      @apply border-neutral-500;
    }

    // input
    .MuiInputBase-input {
      @apply py-2.5 px-2 h-10 box-border text-neutral-800 text-body;
    }
    &:hover:not(.Mui-focused):not(.Mui-disabled) {
      @apply bg-primary-50;
    }
    &:hover.Mui-focused .MuiOutlinedInput-notchedOutline {
      @apply border-primary-700;
    }

    &.MuiInputBase-multiline {
      @apply px-3 py-1;
    }
  }

  //label
  .MuiInputLabel-root {
    @apply text-neutral-600 text-body top-5 -translate-y-1/2 left-2 transition-all;

    &.prefix:not(.MuiFormLabel-filled, .Mui-focused) {
      @apply pl-6;
    }
  }

  .MuiInputLabel-root.input-rcs-label {
    @apply pl-20;
  }

  //label active
  .MuiFormLabel-filled,
  .Mui-focused {
    &.MuiInputLabel-root {
      @apply text-footnote top-0 left-[5px] pr-1 transition-all bg-white pl-[5px];
    }

    .MuiOutlinedInput-notchedOutline {
      @apply border-neutral-500;

      legend {
        @apply pr-1;
        & > span {
          @apply pl-1;
        }
      }
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      @apply border border-primary-700;
    }
  }

  .MuiFormHelperText-root {
    @apply h-4.5 text-footnote m-0;
  }

  .MuiFormLabel-asterisk {
    @apply text-accent-red;
  }
}

.MuiFormControl-root.MuiTextField-root.textFieldError {
  .MuiInputBase-root {
    // field set
    .MuiOutlinedInput-notchedOutline {
      @apply border-accent-red;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      @apply border-accent-red;
    }

    // input
    .MuiInputBase-input {
      @apply text-neutral-800;
    }
    @apply bg-red-50;

    &:hover {
      @apply bg-red-50;
    }

    &:hover.Mui-focused .MuiOutlinedInput-notchedOutline {
      @apply border-accent-red;
    }
  }

  //label
  .MuiInputLabel-root {
    @apply text-red-700;
  }

  //label active
  .MuiFormLabel-filled,
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      @apply border-accent-red;
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      @apply border border-accent-red;
    }
  }

  .MuiFormHelperText-root {
    @apply text-accent-red;
  }

  .MuiFormLabel-asterisk {
    @apply text-accent-red;
  }
}

.MuiFormControl-root.MuiTextField-root.textFieldDisabled:not(.textReadOnly) {
  @apply cursor-not-allowed;
  .MuiInputBase-root {
    // field set
    .MuiOutlinedInput-notchedOutline {
      @apply border-neutral-300;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      @apply border-neutral-300;
    }

    // input
    .MuiInputBase-input {
      @apply text-neutral-400 cursor-not-allowed;
    }
    @apply border-neutral-200;

    &:hover.Mui-focused .MuiOutlinedInput-notchedOutline {
      @apply border-neutral-300;
    }
  }

  //label
  .MuiInputLabel-root {
    @apply text-neutral-400;
  }

  //label active
  .MuiFormLabel-filled,
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      @apply border-neutral-300;
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      @apply border border-neutral-300;
    }
  }

  .MuiFormHelperText-root {
    @apply text-neutral-300;
  }

  .MuiFormLabel-asterisk {
    @apply text-neutral-300;
  }
}

.MuiFormControl-root.MuiTextField-root.textFieldDisabled.textReadOnly {
  .MuiInputBase-root {
    @apply bg-neutral-50;
    .MuiInputBase-input {
      @apply text-neutral-500;
      -webkit-text-fill-color: rgba(var(--color-neutral-500));
    }
    .MuiOutlinedInput-notchedOutline {
      @apply border-neutral-300;
    }
  }
  .MuiFormLabel-root.Mui-disabled {
    @apply text-neutral-400;
  }
  .MuiFormLabel-asterisk {
    @apply text-accent-red;
  }
}

.input-password-passed {
  .MuiFormHelperText-root {
    @apply text-accent-green;
  }
}

.input-rcs {
  .MuiFormControl-root.MuiTextField-root {
    .MuiInputBase-root {
      @apply pl-0;
    }
    .MuiInputBase-root {
      .MuiInputBase-input.MuiInputBase-inputAdornedStart {
        @apply pl-6;
      }
    }
    .MuiInputBase-root.appSelect {
      &:hover:not(.Mui-focused):not(.Mui-disabled) {
        @apply border-0 border-r border-solid border-neutral-500 rounded-e-none;
      }
    }
  }
}

.input-hide-spinner::-webkit-inner-spin-button,
.input-hide-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-hide-spinner {
  -moz-appearance: textfield;
}

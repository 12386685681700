.appSelect,
.appSelect {
  .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline:hover {
    @apply rounded-lg border border-solid border-neutral-500;
  }
  .MuiSelect-select.MuiSelect-outlined {
    @apply font-general text-body rounded-lg;
    &:hover:not(.Mui-disabled) {
      @apply bg-primary-50;
    }

    &.Mui-disabled {
      @apply cursor-not-allowed;
    }
  }
}
.appSelect.MuiInputBase-root.MuiOutlinedInput-root:hover:not(.Mui-error):not(
    .Mui-disabled
  ) {
  .MuiOutlinedInput-notchedOutline {
    @apply border-neutral-500 bg-transparent;
  }
}
.appSelect.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline:not(.Mui-error) {
  @apply border border-solid border-primary-700;
}
.appSelect.MuiInputBase-root.MuiOutlinedInput-root:hover.Mui-error:not(
    .Mui-disabled
  ) {
  .MuiOutlinedInput-notchedOutline {
    @apply bg-red-50 -z-10;
  }
}
.appSelect.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-error:not(
    .Mui-disabled
  )
  .MuiOutlinedInput-notchedOutline {
  @apply border border-solid border-accent-red;
}

.appSelect.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  .MuiOutlinedInput-notchedOutline {
    @apply border border-solid border-neutral-300 bg-neutral-50 -z-10;
  }
  .MuiSelect-select.MuiSelect-outlined.Mui-disabled {
    @apply text-neutral-400 border border-neutral-200 border-solid;
  }
  svg {
    @apply hidden;
  }
}

ul.selected-paper-item .MuiMenuItem-root.Mui-selected {
  @apply bg-primary-50 text-primary-main;
}

ul.filter-selected-paper-item .MuiMenuItem-root.Mui-selected {
  @apply bg-white text-neutral-800;
}

.appSelect.Mui-readOnly {
  @apply pointer-events-none;
  .MuiOutlinedInput-notchedOutline {
    @apply border-neutral-300;
  }
}

.appSelect.rscSelect,
.appSelect.rscSelect.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
  fieldset.MuiOutlinedInput-notchedOutline:not(.Mui-error) {
    @apply border-0 rounded-none border-r border-neutral-300;
  }
}

.appDropdownTable {
  @apply w-full;
  .MuiSelect-select.MuiSelect-outlined {
    @apply h-10 box-border flex items-center;
    &.Mui-error:hover {
      @apply border border-solid border-red-700 bg-red-50;
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
@import './lib/common/reset';
@import './lib/common/global.scss';
@import './lib/common/variable.scss';
@import './lib/common/font.scss';
@import './lib/common/stepper.scss';
@import './lib/common/button.scss';
@import './lib/common/input.scss';
@import './lib/common/autocomplete.scss';
@import './lib/common/tooltip.scss';
@import './lib/common/typography.scss';
@import './lib/common/select.scss';
@import './lib/common/switch.scss';
@import './lib/common/toggleButton.scss';
@import './lib/common/daterange.scss';

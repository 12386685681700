.MuiAutocomplete-root .MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
  @apply p-0;
}

.MuiPopper-root {
  .MuiAutocomplete-paper {
    @apply mt-3 !shadow-brg1 rounded-lg text-body text-neutral-700;

    .MuiAutocomplete-loading {
      @apply bg-primary-50;
    }
  }

  .MuiAutocomplete-option:hover {
    @apply bg-primary-50;
  }
  .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'] {
    @apply bg-primary-50;
  }
}

.MuiAutocomplete-root {
  .MuiFormLabel-root.Mui-disabled,
  .MuiInputBase-root.Mui-disabled .MuiInputAdornment-root {
    @apply text-neutral-300 bg-transparent;
  }

  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    @apply text-neutral-500;
  }

  .MuiInputBase-root.MuiOutlinedInput-root:hover.Mui-disabled
    .MuiOutlinedInput-notchedOutline,
  .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    @apply border-neutral-300 bg-neutral-50 -z-10;
  }
}

.MuiToggleButtonGroup-root {
  @apply border border-solid border-secondary-light min-w-60 w-fit;
}

.MuiButtonBase-root.MuiToggleButton-root {
  @apply w-1/2 capitalize text-h5-title;
}

.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
  @apply bg-secondary-light border-none outline-none hover:bg-secondary-light mx-0 rounded-lg text-white;
}
.MuiButtonBase-root.MuiToggleButton-root:not(.Mui-selected) {
  @apply border-none outline-none hover:bg-transparent text-neutral-600;
}

main {
  @apply bg-primary-50 max-h-[calc(100vh_-_100px)] overflow-y-auto overflow-x-hidden;
}

header {
  @apply h-25;
}

nav {
  @apply max-h-screen;
}

.dateRange {
  .MuiDateCalendar-root {
    .MuiDayCalendar-header {
      @apply pb-1 w-[252px];
    }
    .MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer {
      @apply overflow-hidden h-[240px] w-fit;
      .MuiYearCalendar-root {
        @apply min-h-[240px] h-[240px] w-[252px];
      }
    }
    &.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
      @apply min-h-50;
    }
    .MuiDayCalendar-weekContainer {
      @apply m-0 gap-0 pb-1;
      .MuiButtonBase-root.MuiPickersDay-root {
        @apply text-body font-semibold text-center p-0 m-0;
        &:not(
            .Mui-selected,
            .dayInRange,
            .startDaySelected,
            .endDaySelected,
            .MuiPickersDay-today,
            .MuiPickersDay-dayOutsideMonth
          ) {
          @apply border-none rounded-none size-9 text-neutral-700;
          &:hover {
            @apply text-primary-800 bg-transparent;
          }
        }
        &.MuiPickersDay-today {
          @apply border-none;
        }
        &.MuiPickersDay-dayOutsideMonth,
        &.Mui-disabled {
          @apply text-neutral-400 bg-transparent border-none;
        }

        &.MuiPickersDay-today:not(
            .MuiPickersDay-dayOutsideMonth,
            .Mui-disabled
          ) {
          @apply text-primary-main border border-solid border-primary-300 rounded-lg bg-primary-background;
        }
        &.dayInRange:not(.Mui-disabled) {
          @apply bg-secondary-50 border-none rounded-none size-9 text-neutral-900 hover:text-primary-800;
          &.MuiPickersDay-dayOutsideMonth {
            @apply cursor-none;
          }
          &.MuiPickersDay-today:not(.MuiPickersDay-dayOutsideMonth) {
            @apply rounded-none bg-secondary-50;
          }
        }
        &.startDaySelected:not(
            .MuiPickersDay-today,
            .MuiPickersDay-dayOutsideMonth,
            .Mui-disabled
          ) {
          @apply bg-secondary-light border-0 rounded-tl-lg rounded-bl-lg rounded-tr-none rounded-br-none;
        }
        &.endDaySelected:not(
            .MuiPickersDay-today,
            .MuiPickersDay-dayOutsideMonth,
            .Mui-disabled
          ) {
          @apply bg-secondary-light border-0 rounded-tr-lg rounded-br-lg rounded-tl-none rounded-bl-none;
        }
      }
    }
  }
}

.MuiStepper-root {
  &.MuiStepper-horizontal {
    @apply mx-auto;
  }
  .MuiStepConnector-root {
    @apply top-[21px];
    .MuiStepConnector-line {
      @apply h-1.5 border-none;
    }
  }
  .MuiStepLabel-iconContainer {
    @apply flex justify-center items-center w-12 h-12 bg-gray-100 rounded-[50%] z-10 pr-0;
    svg {
      @apply w-8 h-8 text-gray-500;
    }
    circle {
      @apply text-gray-100;
    }
    .MuiStepIcon-text {
      @apply text-gray-500 fill-gray-500;
    }
  }
  .Mui-active,
  .Mui-completed {
    .MuiStepConnector-line {
      @apply bg-secondary-100;
    }
    .MuiStepLabel-iconContainer {
      @apply bg-secondary-100 cursor-pointer;
      svg {
        @apply text-secondary-dark;
      }
    }
  }

  .Mui-disabled {
    .MuiStepConnector-line,
    .MuiStepLabel-iconContainer {
      @apply bg-gray-100;
    }
  }
}

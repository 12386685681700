:root {
  /* Primary colors */
  --color-primary-50: 244, 242, 255 /* #F4F2FF */;
  --color-primary-100: 234, 232, 246 /* #EAE8F6 */;
  --color-primary-200: 213, 209, 238 /* #D5D1EE */;
  --color-primary-300: 192, 187, 229 /* #C0BBE5 */;
  --color-primary-400: 170, 163, 221 /* #AAA3DD */;
  --color-primary-500: 148, 141, 213 /* #948DD5 */;
  --color-primary-600: 128, 118, 205 /* #8076CD */;
  --color-primary-700: 108, 95, 196 /* #6C5FC4 */;
  --color-primary-800: 89, 73, 189 /* #5949BD */;
  --color-primary-900: 74, 61, 171 /* #4A3DAB */;
  --color-primary-main: 63, 51, 153 /* #3F3399 */;
  --color-primary-background: 246, 245, 255, 1 /* #F6F5FF */;

  /* Secondary colors */
  --color-secondary-main: 2, 203, 205 /* #02cbcd */;
  --color-secondary-dark: 2, 125, 125 /* #027d7d */;
  --color-secondary-light: 0, 177, 178 /* #00b1b2 */;
  --color-secondary-50: 236, 248, 248 /* #ECF8F8 */;
  --color-secondary-100: 217, 243, 243 /* #d9f3f3 */;
  --color-secondary-200: 179, 231, 232 /* #b3e7e8 */;
  --color-secondary-300: 127, 215, 216 /* #7fd7d8 */;

  /* Teal colors */
  --color-teal-50: 249, 255, 255; /* #f9ffff */
  --color-teal-100: 200, 251, 255; /* #c8fbff */
  --color-teal-200: 148, 230, 235; /* #94e6eb */
  --color-teal-300: 90, 208, 213; /* #5ad0d5 */
  --color-teal-400: 43, 194, 197; /* #2bc2c5 */
  --color-teal-500: 0, 177, 178; /* #00b1b2 */
  --color-teal-600: 0, 163, 162; /* #00a3a2 */
  --color-teal-700: 17, 148, 146; /* #119492 */
  --color-teal-800: 0, 132, 130; /* #008482 */
  --color-teal-900: 8, 102, 97; /* #086661 */

  /* Yellow colors */
  --color-yellow-50: 254, 239, 211 /* #feefd3 */;
  --color-yellow-100: 254, 234, 196 /* #feeac4 */;
  --color-yellow-200: 253, 226, 176 /* #fde2b0 */;
  --color-yellow-300: 253, 217, 150 /* #fdd996 */;
  --color-yellow-400: 252, 204, 115 /* #fccc73 */;
  --color-yellow-500: 251, 186, 69 /* #fbba45 */;
  --color-yellow-600: 250, 170, 22 /* #faaa16 */;
  --color-yellow-700: 226, 149, 5 /* #e29505 */;
  --color-yellow-800: 192, 126, 4 /* #c07e04 */;
  --color-yellow-900: 163, 107, 4 /* #a36b04 */;

  /* Red colors */
  --color-red-50: 255, 237, 233 /* #FFEDE9 */;
  --color-red-100: 255, 223, 217 /* #FFDFD9 */;
  --color-red-200: 240, 172, 168 /* #F0ACA8 */;
  --color-red-300: 220, 149, 144 /* #DC9590 */;
  --color-red-400: 237, 124, 109 /* #ED7C6D */;
  --color-red-500: 243, 111, 85 /* #F36F55 */;
  --color-red-600: 227, 103, 83 /* #E36753 */;
  --color-red-700: 209, 91, 75 /* #D15B4B */;
  --color-red-800: 197, 86, 69 /* #C55645 */;
  --color-red-900: 183, 77, 57 /* #B74D39 */;

  /* blue colors */
  --color-blue-100: 221, 234, 255, 1 /* #0A6CFF */;
  --color-blue-600: 10, 108, 255, 1 /* #DDEAFF */;

  /* Neutral colors */
  --color-neutral-50: 249, 250, 251; /* #F9FAFB */
  --color-neutral-100: 243, 244, 246; /* #F3F4F6 */
  --color-neutral-200: 229, 231, 235; /* #E5E7EB */
  --color-neutral-300: 209, 213, 219; /* #D1D5DB */
  --color-neutral-400: 156, 163, 175; /* #9CA3AF */
  --color-neutral-500: 107, 114, 128; /* #6B7280 */
  --color-neutral-600: 75, 85, 99; /* #4B5563 */
  --color-neutral-700: 55, 65, 81; /* #374151 */
  --color-neutral-800: 31, 42, 55; /* #1F2A37 */
  --color-neutral-900: 17, 25, 40; /* #111928 */

  /* Accent colors */
  --color-accent-yellow: 251, 186, 69 /* #fbba45 */;
  --color-accent-red: 234, 91, 63 /* #ea5b3f */;
  --color-accent-green: 19, 194, 150 /* #13c296 */;
  --color-accent-blue: 0, 92, 229 /* #005ce5 */;

  /* Background color */
  --color-background-default: 255, 255, 255 /* #ffffff */;

  /* Button color */
  --color-button-color: 52, 34, 35 /* #34223B */;

  /* Common color */
  --color-white: 255, 255, 255 /* #ffffff */;
  --color-text: 17, 17, 17 /* #111111 */;
  --color-text-grey: 71, 71, 71 /* #474747 */;
  --color-dark-container-bg: 38, 38, 38 /* #262626 */;
  --color-general-bg: 246, 246, 248 /* #f6f6f8 */;
  --color-separation-line: 192, 187, 229 /* #c0bbe5 */;

  /* SHADOWS */
  --shadow-brg1: 2px 2px 10px 0 rgba(0 0 0 / 0.1);
  --shadow-brg2: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  --shadow-brg3: 0px 10px 15px -3px rgba(18, 14, 48, 0.1),
    0px 4px 6px 0px rgba(18, 14, 48, 0.05);
  --shadow-brg4: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  --shadow-2: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);

  // font-size
  --font-size-h1: 2.375rem /* 38px */;
  --font-size-h2: 1.875rem /* 30x */;
  --font-size-h3: 1.5rem /* 24px */;
  --font-size-h4: 1.25rem /* 20px */;
  --font-size-h5-title: 1.125rem /* 18px */;
  --font-size-h5-general: 1rem /* 16px */;

  --font-size-body: 0.875rem /* 14px */;
  --font-size-footnote: 0.75rem /* 12px */;

  // line-height
  --line-height-h1: 2.875rem /* 46px */;
  --line-height-h2: 2.375rem /* 38px */;
  --line-height-h3: 2rem /* 32px */;
  --line-height-h4: 1.75rem /* 28px */;
  --line-height-h5-title: 1.6125rem /* 26px */;
  --line-height-h5-general: 1.5rem /* 24px */;

  --line-height-body: 1.375rem /* 22px */;
  --line-height-footnote: 1.25rem /* 20px */;

  // spacing
  --spacing-1: 0.5rem /**8px */;
  --spacing-2: 1rem /**16px */;
  --spacing-3: 1.5rem /**24px */;
  --spacing-4: 2rem /**32px */;
  --spacing-5: 2.5rem /**40px */;
  --spacing-6: 3.5rem /**56px */;
  --spacing-7: 4.5rem /**72px */;
  --spacing-8: 5rem /**80px */;
  --spacing-9: 6rem /**96px */;
  --spacing-10: 7.5rem /**120px */;
  --spacing-11: 3.75rem /**60px */;
  --spacing-12: 8.25rem /**130px */;
  --spacing-13: 3.25rem /**52px */;
  --spacing-14: 6.25rem /**100px */;
  --spacing-15: 4.75rem /**76px */;
  --spacing-16: 8.75rem /**140px**/;
  --spacing-17: 6.5rem /**104px */;
  --spacing-18: 11.25rem /**180px */;
  --spacing-19: 23rem /**368px */;
  --spacing-20: 23.75rem /**380px */;
  --spacing-21: 7.5rem /**120px */;
  --spacing-22: 1.125rem /**18px */;
  --spacing-23: 12.5rem /**200px */;
  --spacing-24: 26.5rem /**424px */;
}

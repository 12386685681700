/* width */
::-webkit-scrollbar {
  @apply w-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-neutral-100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply rounded-full bg-neutral-300;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply rounded-full bg-neutral-400;
}

// overdrive global class of material UI

/* FormControl */
.MuiFormControl-root .MuiFormHelperText-root {
  @apply h-5 text-neutral-600 text-footnote font-general m-0;

  &.textFieldError {
    @apply text-accent-red;
  }
}

.MuiFormLabel-root.MuiInputLabel-root {
  @apply font-general text-body text-neutral-500;
  .MuiFormLabel-asterisk {
    @apply text-accent-red;
  }
}
.MuiFormLabel-root.MuiInputLabel-root.Mui-focused:not(.Mui-error):not(
    .Mui-disabled
  ) {
  @apply text-primary-700;
  span.MuiFormLabel-asterisk {
    @apply text-accent-red;
  }
}
.MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-filled:not(.Mui-focused):not(
    .Mui-error
  ):not(.Mui-disabled) {
  @apply text-neutral-500;
}
.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled {
  @apply text-neutral-400 bg-white;
  span.MuiFormLabel-asterisk {
    @apply text-neutral-400 bg-transparent;
  }
}
